import { Trans } from '@lingui/macro';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

import router from 'next/router';
import type { ComponentType } from 'react';
import type { StoreProductWishlistComponent } from '../../../types.d/storeProduct';
import { toBase64, Shimmer } from '../../utility/imageShimmer/ImageShimmer';
import styles from './NailArtCard.module.scss';

export type NailArtCardProps = {
  image: string;
  imageTitle: string;
  title: string;
  url: string;
  newTab?: boolean;
  isLoading: boolean;
  proOnly?: boolean;
  author?: string;
  description: string;
  wishlistComponent?: ComponentType<StoreProductWishlistComponent>;
};

/**
 * The primary Nail Art Card component.
 * @param props - The card props.
 */
export const NailArtCard = (props: NailArtCardProps) => {
  const {
    isLoading,
    url = '/',
    title,
    newTab = false,
    image,
    imageTitle,
    proOnly,
    author,
    description,
    wishlistComponent: WishlistComponent,
  } = props;

  const handle = url?.split('/').pop() ?? '';

  const clickHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (newTab) {
      window.open(url, '_blank');
      return;
    }

    event.preventDefault();

    router.push(url);
  };

  /**
   * Only show the wishlist button if the URL includes `/nail-art/`
   * - Ensures that content that `/professionals` content does not show the
   *   wishlist button.
   */
  const showWishlistButton = url.includes('/nail-art/');

  if (isLoading) {
    return <NailArtCardLoading {...props} />;
  }

  return (
    <div className={styles.nailArtCard}>
      <div className={styles.nailArtCard__cardWrapper}>
        <div className={styles.nailArtCard__imageWrapper}>
          {image && (
            <Image
              className={styles.nailArtCard__image}
              src={image}
              title={imageTitle} // requested change to fullfill the SEO requirement
              alt={imageTitle}
              fill
              onClick={clickHandler}
              sizes="(min-width: 1024px) 300px, 50vw"
              blurDataURL={`data:image/svg+xml;base64,${toBase64(
                Shimmer(100, 130)
              )}`}
            />
          )}

          {proOnly && (
            <div className={styles.nailArtCard__proOnlyContainer}>
              <div
                className={clsx(
                  styles.nailArtCard__proOnlyStyles,
                  'text-utility-utility-small'
                )}
              >
                PRO ONLY
              </div>
            </div>
          )}

          {showWishlistButton && WishlistComponent && (
            <div className={styles.nailArtCard__wishlistWrapper}>
              <WishlistComponent handle={handle} itemType="nailArt" />
            </div>
          )}
        </div>

        <Link
          href={url}
          className={styles.nailArtCard__footerContainer}
          target={newTab ? '_blank' : undefined}
        >
          <h3
            className={clsx(
              styles.nailArtCard__title,
              'text-body-1-desktop',
              'text-body-1-mobile'
            )}
          >
            {title}
          </h3>

          {author && (
            <p
              className={clsx(
                styles.nailArtCard__author,
                'text-utility-utility-small-desktop',
                'text-utility-utility-small-mobile'
              )}
            >
              {author}
            </p>
          )}

          <p
            className={clsx(
              styles.nailArtCard__description,
              'text-body-small-desktop',
              'text-body-caption'
            )}
          >
            {description}
          </p>
        </Link>
      </div>
    </div>
  );
};

const NailArtCardLoading = ({
  title,
  author,
  description,
}: NailArtCardProps) => {
  return (
    <div className={clsx(styles.nailArtCard, 'is-loading')}>
      <div className={styles.nailArtCard__cardWrapper}>
        <div className={styles.nailArtCard__imageWrapper}>
          <div className={styles.nailArtCard__image} />
        </div>

        <div className={styles.nailArtCard__footerContainer}>
          <h3
            className={clsx(
              styles.nailArtCard__title,
              'text-body-1-desktop',
              'text-body-1-mobile'
            )}
          >
            {title ?? <Trans id="accessibility.loading">Loading...</Trans>}
          </h3>

          <p
            className={clsx(
              styles.nailArtCard__author,
              'text-utility-utility-small-desktop',
              'text-utility-utility-small-mobile'
            )}
          >
            {author ?? <Trans id="accessibility.loading">Loading...</Trans>}
          </p>

          <p
            className={clsx(
              styles.nailArtCard__description,
              'text-body-small-desktop',
              'text-body-caption'
            )}
          >
            {description ?? (
              <Trans id="accessibility.loading">Loading...</Trans>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
