import clsx from 'clsx';
import type { ComponentType } from 'react';
import { useEffect, useState } from 'react';
import { NailArtCard } from '../../../components/cards/nailArtCard/NailArtCard';
import type { NailArtCardProps } from '../../../components/cards/nailArtCard/NailArtCard';
import { Button } from '../../../components/ui/button/Button';
import { ScrollSnapSlider } from '../../../components/utility/scrollSnapSlider/ScrollSnapSlider';
import type { StoreProductWishlistComponent } from '../../../types.d/storeProduct';
import styling from './NailArtSection.module.scss';

export interface NailArtSectionProps {
  title?: string;
  subtitle?: string;
  tabs: Array<NailArtCardTabType>;
  wishlistComponent?: ComponentType<StoreProductWishlistComponent>;
}

export type NailArtCardTabType = {
  title: string;
  nailArtCardTab: Array<NailArtCardProps>;
  link?: string;
  isLoading?: boolean;
};

export const NailArtSection = ({
  title,
  subtitle,
  tabs,
  wishlistComponent: WishlistComponent,
}: NailArtSectionProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    setActiveTabIndex(0);
    setFirstLoad(false);
  }, [firstLoad]);

  return (
    <section className={styling.nailArtCollection}>
      <div
        className={clsx(styling.nailArtCollection__headerWrapper, 'container')}
      >
        <div className={styling.nailArtCollection__titleWrapper}>
          {title && (
            <h2
              className={[
                styling.nailArtCollection__title,
                'text-heading-h2-mobile',
                'text-heading-h2-desktop',
              ].join(' ')}
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <h2
              className={[
                styling.nailArtCollection__subtitle,
                'text-heading-h4-mobile',
                'text-heading-h3-desktop',
              ].join(' ')}
            >
              {subtitle}
            </h2>
          )}
        </div>

        <div
          className={clsx(
            tabs.length < 2
              ? styling.nailArtCollection__headerMenuHidden
              : styling.nailArtCollection__headerMenuWrapper
          )}
        >
          {tabs.length > 1 &&
            tabs.map((tab, index) => (
              <Button
                key={index}
                type={'button'}
                className={styling.nailArtCollection__headerButton}
                modifiers={
                  activeTabIndex === index ? [] : ['white', 'outlined']
                }
                href={tab.link}
                onClick={() => !tab.link && setActiveTabIndex(index)}
              >
                <h3
                  className={[
                    styling.nailArtCollection__headerButtonText,
                    'text-utility-utility-small',
                    'text-utility-utility-desktop',
                  ].join(' ')}
                >
                  {tab.title}
                </h3>
              </Button>
            ))}
        </div>
      </div>

      <div
        className={clsx(
          styling.nailArtCollection__carouselWrapper,
          styling.nailArtCollection__carousel
        )}
      >
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={clsx(
              activeTabIndex !== index &&
                styling.nailArtCollection__hideCarousel
            )}
          >
            <ScrollSnapSlider
              key={index}
              id="nailArtCollectionCarousel"
              spacing="5px"
              height={null}
            >
              {tab.nailArtCardTab.map((card, innerIndex) => (
                <div
                  className={styling.nailArtCollection__cardWrapper}
                  key={innerIndex}
                >
                  <NailArtCard
                    isLoading={card.isLoading}
                    url={card.url}
                    title={card.title}
                    newTab={card.newTab}
                    image={card.image}
                    imageTitle={card.imageTitle}
                    author={card.author}
                    description={card.description}
                    proOnly={card.proOnly}
                    wishlistComponent={WishlistComponent}
                  />
                </div>
              ))}
            </ScrollSnapSlider>
          </div>
        ))}
      </div>
    </section>
  );
};
